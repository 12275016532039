import React, { useEffect } from "react";
import { navigate } from "@reach/router";


const IndexPage = () => {
  useEffect(() => {
    navigate("https://mkt.emmim.pt");
  }, []);
  return <></>;
};

export default IndexPage;
